import { faInfoCircle, faMinus, faTimes, faUserPen } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/ui/Flex';
import IconButton from 'components/ui/IconButton';
import Input from 'components/ui/Input';
import useAppSelector from 'hooks/useAppSelector';
import useBreakPoints from 'hooks/useBreakPoints';
import { CartCollectionSkuInput, CartItemSkuAddOn } from 'microshop-api';
import { useState } from 'react';
import styled from 'styled-components/macro';
import { BreakPointSize } from 'types/styled';
import ArticleInfo from './ArticleInfo';
import { QuantityChangedFunction } from './CartItems';
import { isSameCollectionVariation } from 'store/reducers/cartSlice';

type InputRowProps = {
    input?: CartCollectionSkuInput;
    quantity?: number;
    index: number;
    sku: any;
    onQtyChange?: QuantityChangedFunction;
    onEditRefinements?: (i: number) => void;
    onRemoveRefinements?: (i: number) => void;
    forceSize?: BreakPointSize;
    collectionId?: number | null;
    splitId?: number | undefined;
    split?: string | null;
};

export const CartRowInput = ({
    input,
    quantity,
    sku,
    forceSize,
    collectionId,
    split,
    splitId,
    index,
    onQtyChange,
}: InputRowProps) => {
    const bp = useBreakPoints(forceSize);
    const { localCart, loading } = useAppSelector(({ cart }) => cart);

    const [articleInfo, setArticleInfo] = useState<string | undefined>(undefined);
    const skuNumber = sku.sku!;

    const handleRemove = () => {
        handleChange(0, true);
    };

    const handleChange = (newQty: number, immediate?: boolean) => {
        if (!onQtyChange || Number.isNaN(newQty) || !input) return;
        onQtyChange({
            sku: skuNumber,
            qty: newQty,
            splitId: splitId,
            collectionId: collectionId,
            split: split,
            inputs:
                input.values?.map((i) => ({
                    key: i.key,
                    input: i.input,
                })) ?? [],
            immediate,
        });
    };

    if (!input) return null;

    const sm = bp.smBelow;
    const xs = bp.xsBelow;

    return (
        <>
            <StyledContainer className="w-100">
                <AddOnBar className="p-2" />
                <SkuRow className="p-2 f3-500 w-100">
                    <ProductInfoCell>
                        <Flex align="center" $wrap>
                            {index}
                            <ImageWrapper className="me-2 p-1">
                                <ImageIcon justify="center">
                                    <FontAwesomeIcon size="lg" icon={faUserPen} />
                                </ImageIcon>
                            </ImageWrapper>
                            <ProductInfo column>
                                {input.values?.map((value, i) => (
                                    <span className="f1-500">
                                        {value.label}: {value.input}
                                    </span>
                                ))}
                            </ProductInfo>
                        </Flex>
                    </ProductInfoCell>
                    <QtyCell>
                        {input?.id ? (
                            <CartInput
                                key={input.id + 'qty'}
                                pending={loading}
                                max={1000}
                                onQtyChange={(e) => handleChange(+e.target.value)}
                                quantity={input.quantity!}
                            ></CartInput>
                        ) : (
                            `${quantity ?? ''} `
                        )}
                        {sm && <PriceText className="mt-2"></PriceText>}
                    </QtyCell>
                    {!sm && !xs && (
                        <>
                            <RightCell></RightCell>
                            <RightCell></RightCell>
                        </>
                    )}
                    {!xs && input.id && (
                        <RemoveCell className="d-flex justify-content-end align-self-center">
                            <IconButton
                                className="flex-shrink-0"
                                icon={faTimes}
                                onClick={handleRemove}
                                disabled={loading}
                            />
                        </RemoveCell>
                    )}
                </SkuRow>
            </StyledContainer>
            {articleInfo && (
                <InfoCell className="p-2" align="center" justify="between">
                    <Flex align="center">
                        <CellIcon icon={faInfoCircle} className="ms-2 me-3" />
                        {articleInfo}
                    </Flex>
                    <IconButton className="flex-shrink-0" icon={faMinus} onClick={() => setArticleInfo(undefined)} />
                </InfoCell>
            )}
        </>
    );
};

export default CartRowInput;

const ImageIcon = styled(Flex)`
    width: 48px;
`;

const ImageWrapper = styled.div<{ as?: string; darkBg?: boolean }>`
    width: 56px;
    min-width: 56px;
    color: ${({ theme }) => theme.colors.textLight};
`;

const ProductInfo = styled(Flex)`
    font-size: 10px;
    color: ${({ theme }) => theme.colors.textLight};
`;

const StyledContainer = styled(Flex)<{ disabled?: boolean }>`
    ${({ disabled }) =>
        disabled &&
        `
            pointer-events: none;
            opacity: 0.6;
        `};
`;
type CartInputProps = {
    onQtyChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    quantity: number;
    pending: boolean;
    disabled?: boolean;
    max: number;
};
const CartInput: React.FC<CartInputProps> = ({ onQtyChange, quantity, pending, disabled, max }) => (
    <InputQuantity
        min={0}
        max={max}
        disabled={disabled || pending}
        type="number"
        defaultValue={quantity}
        onChange={onQtyChange}
    />
);

const AddOnBar = styled.div`
    background: ${({ theme }) => theme.colors.fillGray};
`;

const TableRow = styled(Flex)`
    font-size: 12px;
    align-items: center;
`;

const SkuRow = styled(TableRow)<{ invalid?: boolean }>`
    background: #fff;
    min-height: 59px;
    margin-bottom: 1px;
    justify-content: flex-end;
    flex-wrap: wrap;

    ${({ theme }) => theme.media.sm} {
        flex-wrap: nowrap;
    }

    ${({ invalid }) => invalid && 'opacity: 0.6;'}
`;

const Cell = styled.div`
    flex-shrink: 0;
`;

const ProductInfoCell = styled(Cell)`
    flex-grow: 2;
    flex-shrink: 2;
`;

const RightCell = styled(Cell)`
    text-align: right;
    width: 85px;
`;

const QtyCell = styled(RightCell)`
    width: 90px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-self: center;
    justify-content: stretch;

    > *:nth-child(2) {
        line-height: 1;
    }
`;

const InputQuantity = styled(Input)`
    border-radius: 0;
    width: 88px;
    margin-bottom: 4px;
    ${({ theme }) => theme.media.xs} {
        margin-bottom: 0;
        width: 68px;
    }
`;

const InfoText = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
`;

const PriceTextDiscount = styled(InfoText)`
    color: ${({ theme }) => theme.colors.attention};
`;

const PriceText = styled(InfoText)`
    color: ${({ theme }) => theme.colors.price};
`;

const InfoCell = styled(Flex)`
    white-space: normal;
`;

const CellIcon = styled(FontAwesomeIcon)`
    font-size: 16px;
`;

const RemoveCell = styled(RightCell)`
    width: calc(34px + 0.8rem);
`;
