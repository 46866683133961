"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CartCollectionSkuInputValueToJSON = exports.CartCollectionSkuInputValueFromJSONTyped = exports.CartCollectionSkuInputValueFromJSON = exports.instanceOfCartCollectionSkuInputValue = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the CartCollectionSkuInputValue interface.
 */
function instanceOfCartCollectionSkuInputValue(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfCartCollectionSkuInputValue = instanceOfCartCollectionSkuInputValue;
function CartCollectionSkuInputValueFromJSON(json) {
    return CartCollectionSkuInputValueFromJSONTyped(json, false);
}
exports.CartCollectionSkuInputValueFromJSON = CartCollectionSkuInputValueFromJSON;
function CartCollectionSkuInputValueFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'key': !(0, runtime_1.exists)(json, 'key') ? undefined : json['key'],
        'input': !(0, runtime_1.exists)(json, 'input') ? undefined : json['input'],
        'label': !(0, runtime_1.exists)(json, 'label') ? undefined : json['label'],
    };
}
exports.CartCollectionSkuInputValueFromJSONTyped = CartCollectionSkuInputValueFromJSONTyped;
function CartCollectionSkuInputValueToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'key': value.key,
        'input': value.input,
        'label': value.label,
    };
}
exports.CartCollectionSkuInputValueToJSON = CartCollectionSkuInputValueToJSON;
