"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextValidationToJSON = exports.TextValidationFromJSONTyped = exports.TextValidationFromJSON = exports.TextValidation = void 0;
/**
 *
 * @export
 */
exports.TextValidation = {
    None: 0,
    Integer: 1,
    Letters: 2,
    LettersUpper: 3
};
function TextValidationFromJSON(json) {
    return TextValidationFromJSONTyped(json, false);
}
exports.TextValidationFromJSON = TextValidationFromJSON;
function TextValidationFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.TextValidationFromJSONTyped = TextValidationFromJSONTyped;
function TextValidationToJSON(value) {
    return value;
}
exports.TextValidationToJSON = TextValidationToJSON;
