import Input from 'components/ui/Input';
import { TextValidation } from 'microshop-api';
import { useEffect, useRef } from 'react';

export const TextValidationInput: React.FC<{
    value: string;
    maxChars?: number | null;
    validation?: TextValidation;
    onChange?: (value: string) => void;
    defaultValue?: string;
    required?: boolean;
    placeholder?: string;
    className?: string;
}> = ({ value, maxChars, validation, onChange, defaultValue, required, placeholder, className }) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.value = value;
        }
    }, [value]);

    return (
        <Input
            id="text"
            type="text"
            value={value}
            ref={inputRef}
            required={required}
            defaultValue={defaultValue}
            placeholder={placeholder}
            className={className ?? undefined}
            onInput={(e: React.FormEvent<HTMLInputElement>) => {
                let value = e.currentTarget.value?.trimStart()?.replace(/\s\s+/g, ' ');

                if (validation === TextValidation.Integer) {
                    value = value.replace(/[^0-9]/g, '');
                } else if (validation === TextValidation.Letters) {
                    value = value.replace(/[^a-zA-Z]/g, '');
                } else if (validation === TextValidation.LettersUpper) {
                    value = value.replace(/[^a-zA-Z]/g, '').toUpperCase();
                }

                value = maxChars ? value.slice(0, maxChars) : value;
                e.currentTarget.value = value;
                onChange?.(value);
            }}
        />
    );
};
