import { faAngleLeft, faShoppingBag } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/ui/Flex';
import IconButton from 'components/ui/IconButton';
import Tags from 'components/ui/Tag/Tags';
import Text from 'components/ui/Text';
import VariationDot from 'components/ui/VariationDot';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { CustomTag, ImageExtension, ListProduct, PriceInformation } from 'microshop-api';
import { darken } from 'polished';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { selectProductListTags, selectQuickViewDetails, toggleQuickView } from 'store/reducers/productSlice';
import styled, { ThemeWebColors } from 'styled-components';
import ProductPrice from './ProductPrice';
import getImage, { ImageType } from 'utils/getImage';

export type CardProduct = ListProduct;

interface IProductCardProps {
    product?: CardProduct;
    onBuy?: (ref: React.MutableRefObject<HTMLElement | null>) => void;
    category?: any;
    categories?: any[];
    categoryPath?: any;
    gtm?: boolean;
}

const ProductCard: React.FC<IProductCardProps> = (props) => {
    const { product } = props;
    const dispatch = useAppDispatch();
    const tags = useAppSelector((state) => selectProductListTags(state, product));
    const { quickView, quickViewProduct, categories } = useAppSelector(({ product, assortment }) => ({
        ...product,
        ...assortment,
    }));
    const quickViewVariation = useAppSelector(selectQuickViewDetails).quickViewVariation;
    const isCartAvailable = true;
    // const priceType = useAppSelector(selectPriceType);

    if (!product) return null;
    const featuredColor =
        (quickViewProduct?.productNumber === product?.productNumber && quickViewVariation?.colorCode) ||
        (product?.hasVariations && product?.variations?.find((v) => v.featured)?.colorCode) ||
        undefined;
    const isSelected = quickView && product.productNumber === quickViewProduct?.productNumber;
    // const overrideImage = isSelected ? quickViewVariation?.image?.src : undefined;
    var selectedCategoryKey = Object.keys(categories).find((key) => categories[key].code == product.nodeId);
    var selectedCategory = selectedCategoryKey ? categories[selectedCategoryKey].name : undefined;

    return (
        <GenericProductCard
            {...props}
            product={product}
            featuredColor={undefined}
            isSelected={isSelected}
            category={selectedCategory}
            // overrideImage={overrideImage}
            onBuy={(ref) => {
                dispatch(
                    toggleQuickView({
                        category: product.nodeId ?? '',
                        pnrOrSlug: product.slug ?? product.productNumber!,
                        featuredColor,
                    }),
                );
                props.onBuy && props.onBuy(ref);
            }}
            tags={tags}
            disableBuyButton={!isCartAvailable}
            priceText={undefined}
            showRetail={true}
        />
    );
};

type ProductCardProps = IProductCardProps & {
    featuredColor?: string;
    overrideImage?: string | null;
    isSelected?: boolean;
    product?: CardProduct;
    tags?: CustomTag[];
    rows?: JSX.Element;
    wishlist?: { show: boolean; saved: boolean };
    priceText?: string;
    disableBuyButton?: boolean;
    showRetail?: boolean;
    actions?: JSX.Element;
    imgBg?: boolean;
};
export const GenericProductCard = ({
    product,
    onBuy,
    category,
    categories,
    categoryPath,
    gtm,
    featuredColor,
    isSelected = false,
    overrideImage,
    tags,
    rows,
    wishlist,
    priceText,
    disableBuyButton,
    showRetail,
    actions,
    imgBg,
}: ProductCardProps) => {
    const [showAllColors, setShowAllColors] = useState(false);
    // const showBrand = useAppSelector(({ assortment }) => assortment.showBrand);
    const showBrand = true;
    // const { commerceType } = useAppSelector(({ siteDefinition }) => siteDefinition);
    const cardRef = useRef<HTMLDivElement>(null);

    if (!product) return null;

    const colors = product.hasVariations
        ? product.variations?.map((f) => f.webColors?.map((wc) => wc.toLocaleLowerCase() as keyof ThemeWebColors))
        : [];

    // const handleClick = () => {
    //     gtm && dispatch(gtmProductClick({ product, category }));
    // };

    const productLink = category ? `/product/${category}/${product.slug}` : `/product/${product.slug}`;

    return (
        <Card
            selected={isSelected}
            ref={cardRef}
            className="p-1 overflow-hidden"
            itemProp="ListItem"
            itemScope
            itemType="https://schema.org/Product"
        >
            <meta itemProp="productID" content={product.productNumber || ''} />
            {/* <ProductLink product={product} onClick={handleClick} featuredColor={featuredColor} routed={categoryPath}> */}
            <Link to={productLink}>
                <ImageContainer>
                    <ImageWrapper imgBg={imgBg}>
                        <StyledImage
                            itemProp="image"
                            src={overrideImage ?? getImage(product?.image, ImageType.Preview, ImageExtension.Jpg)}
                            alt={product?.productName || 'product'}
                        />
                    </ImageWrapper>
                </ImageContainer>
            </Link>
            {/* </ProductLink> */}
            <ActionsContainer justify="between" className="pt-3 px-3">
                {tags && <Tags tags={tags} className="me-2" />}
                <div></div>
                {/* <Flex>
                    {wishlist?.show && (
                        <IconButton
                            className="flex-shrink-0 align-self-start"
                            bgColor="fillWhite"
                            icon={faHeart}
                            color={wishlist.saved ? 'sale' : undefined}
                            onClick={toggleWishList}
                        />
                    )}
                    {actions}
                </Flex> */}
            </ActionsContainer>
            <Body column justify="start" className="pt-1 px-2 pb-2 flex-grow-1">
                <div className="px-2 flex-grow-1">
                    <Flex justify="between">
                        <Link className="link-dark" to={productLink}>
                            <Text itemProp="name" large={product && product.productName!?.length < 30}>
                                {product.productName ?? 'Test produkt'}
                                <meta itemProp="name" content={`${product.productName}`} />
                            </Text>
                        </Link>
                    </Flex>
                    <Flex>
                        {showBrand && (
                            <>
                                <Text light small>
                                    {product.productBrandName ?? 'Brand'}
                                    <meta itemProp="brand" content={`${product.productBrandName}`} />
                                </Text>
                                <Text className="mx-1" light small>
                                    {'/'}
                                </Text>
                            </>
                        )}
                        <Text className="text-break" light small>
                            {product.productInternalNumber ? product.productInternalNumber : product.productNumber}
                        </Text>
                    </Flex>
                    {rows && rows}
                    <Flex justify="between">
                        {colors && colors.length > 0 ? (
                            <ColorsContainer align="center" className="my-1">
                                {colors?.map(
                                    (color, i) =>
                                        (showAllColors || i < 5) && (
                                            <Color align="center" key={i} itemProp="color" className="me-1">
                                                <VariationDot colors={color} />{' '}
                                            </Color>
                                        ),
                                )}
                                {colors && colors.length > 5 && (
                                    <Color className="me-1">
                                        <ExpandColors onClick={() => setShowAllColors(!showAllColors)}>
                                            {showAllColors ? (
                                                <FontAwesomeIcon icon={faAngleLeft} />
                                            ) : (
                                                `+ ${colors.length - 5}`
                                            )}
                                        </ExpandColors>
                                    </Color>
                                )}
                            </ColorsContainer>
                        ) : null}
                    </Flex>
                    {/* {showRetail && product.price && (
                    <ProductPrice
                        compact
                        price={product.price}
                        fontSize={12}
                        color="textLight"
                        showPriceType="retail"
                    />
                )} */}
                </div>
                {!disableBuyButton ? (
                    <AddToCart
                        bgColor="fill"
                        square
                        color="price"
                        className="w-100"
                        onClick={() => {
                            onBuy && onBuy(cardRef);
                        }}
                        icon={faShoppingBag}
                    >
                        <PriceText priceText={priceText} price={product?.price} />
                    </AddToCart>
                ) : (
                    <PriceText priceText={priceText} price={product?.price} disabled commerceType={null} />
                )}
            </Body>
        </Card>
    );
};

type PriceTextProps = {
    priceText?: string;
    price?: PriceInformation | null;
    disabled?: boolean;
    commerceType?: any;
};
const PriceText = ({ priceText, price, disabled }: PriceTextProps): JSX.Element => {
    const { t } = useTranslation();
    const text = (text?: string) => {
        return (
            <Text color={disabled ? 'textLight' : 'price'} fontSize={12} className="f1-400">
                {typeof text === 'string' ? text : t('cart.add_one', 'Add to cart')}
            </Text>
        );
    };

    if (typeof priceText === 'string') return text(priceText);
    // if (price)
    return (
        <>
            <ProductPrice price={price!} compact color={disabled ? 'textLight' : 'price'} />
            <span className="visually-hidden">{t('cart.add_one', 'Add to cart')}</span>
        </>
    );
    // return text();
};

const Card = styled.div<{ selected: boolean }>`
    flex: 1;
    ${({ theme, selected }) =>
        selected
            ? `border: ${darken(0.1, theme.colors.fillMedium)} 1px solid`
            : `border: ${theme.colors.fill} 1px solid`};
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: relative;
`;

// const CustomLink = styled(Link)`
//     &:hover {
//         color: ${({ theme }) => theme.colors.text};
//     }
// `;
const ImageContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.fillWhite};
    position: relative;
    overflow: hidden;

    :before {
        padding-bottom: 100%;
        display: block;
        content: '';
        width: 100%;
    }
`;

const ImageWrapper = styled.div<{ imgBg?: boolean }>`
    ${({ theme, imgBg }) => imgBg && `background-color: ${theme.colors.transparentBg};`}
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
`;

const StyledImage = styled.img`
    ${({ theme }) => theme.media.md} {
        height: calc(100% - 40px);
        width: calc(100% - 70px);
        top: 35px;
        left: 35px;
    }
    position: absolute;
    object-fit: contain;
    height: calc(100% - 45px);
    width: calc(100% - 16px);
    top: 35px;
    left: 8px;
`;

const Body = styled(Flex)`
    background-color: ${({ theme }) => theme.colors.fillWhite};
    text-align: left;
    width: 100%;
`;

const ActionsContainer = styled(Flex)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
`;

const ColorsContainer = styled(Flex)`
    flex-wrap: wrap;
`;

const Color = styled(Flex)`
    height: 21px;
`;

const AddToCart = styled(IconButton)``;

const ExpandColors = styled.button`
    font-size: 12px;
    background: none;
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.colors.transparent};
    &:hover {
        border-bottom: 1px solid ${({ theme }) => theme.colors.text};
    }
`;
export default ProductCard;
