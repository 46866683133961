"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CartCollectionSkuInputToJSON = exports.CartCollectionSkuInputFromJSONTyped = exports.CartCollectionSkuInputFromJSON = exports.instanceOfCartCollectionSkuInput = void 0;
var runtime_1 = require("../runtime");
var CartCollectionSkuInputValue_1 = require("./CartCollectionSkuInputValue");
/**
 * Check if a given object implements the CartCollectionSkuInput interface.
 */
function instanceOfCartCollectionSkuInput(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfCartCollectionSkuInput = instanceOfCartCollectionSkuInput;
function CartCollectionSkuInputFromJSON(json) {
    return CartCollectionSkuInputFromJSONTyped(json, false);
}
exports.CartCollectionSkuInputFromJSON = CartCollectionSkuInputFromJSON;
function CartCollectionSkuInputFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'quantity': !(0, runtime_1.exists)(json, 'quantity') ? undefined : json['quantity'],
        'values': !(0, runtime_1.exists)(json, 'values') ? undefined : (json['values'] === null ? null : json['values'].map(CartCollectionSkuInputValue_1.CartCollectionSkuInputValueFromJSON)),
    };
}
exports.CartCollectionSkuInputFromJSONTyped = CartCollectionSkuInputFromJSONTyped;
function CartCollectionSkuInputToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'quantity': value.quantity,
        'values': value.values === undefined ? undefined : (value.values === null ? null : value.values.map(CartCollectionSkuInputValue_1.CartCollectionSkuInputValueToJSON)),
    };
}
exports.CartCollectionSkuInputToJSON = CartCollectionSkuInputToJSON;
