import { createAction, createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18next from 'i18next';
import {
    B2BCheckout,
    CartInput,
    CartItemSkuAddOn,
    CartSummary,
    Checkout,
    CheckoutConfirm,
    Delivery,
    ImageExtension,
    ShopCompleteProduct,
    StandardCheckout,
} from 'microshop-api';
import services from 'services';
import { AppDispatch, RootState } from 'store';
import getImage, { ImageType } from 'utils/getImage';
import { CollectionVariation } from './productSlice';
import { toast } from 'react-toastify';
import CartToast, { CartToastData } from 'components/Cart/CartToast';
import DismissToast from 'components/Cart/DismissToast';
import { ThunkConf } from 'store/thunks';

export enum Valid {
    Unknown,
    Invalid,
    Valid,
}

type Cart = CartSummary;

export type LocalCartInputAddon = {
    id: number;
    quantity: number;
    inputs: string[] | null;
};

export type LocalCartInput = {
    variationNumber: string;
    min?: number;
    sku: string;
    split?: string | null;
    collectionId?: number;
    qty?: number;
    comment?: string;
    date?: Date;
    inboxItems?: number;
    additive?: boolean;
    addOns?: LocalCartInputAddon[];
};
type LocalDate = { date?: Date; requested?: boolean; note?: string; unknown?: boolean };

type LocalQuantity = {
    [key: string]: {
        qty: number;
        inboxItems?: number;
        comment?: string;
        date?: LocalDate;
        addOns?: CartItemSkuAddOn[];
    };
};

export type LocalCartVariation = {
    minQty?: number;
    variationQty: number;
    skus: LocalQuantity;
    variationNumber: string;
    split?: string | null;
    collectionId?: number;
};

export type LocalCart = LocalCartVariation[];

export const localCartSkusSet = createAction<Array<LocalCartInput>>('cart/localCartSkusSet');

export const setSkuDate = createAsyncThunk<
    void,
    { variationNumber: string; split?: string; collectionId?: number; sku: string; date: Date },
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>('/cart/setSkuDate', async (par, { dispatch, getState }) => {
    const date = par.date.toJSON();

    await services.cart.cartSetRequestedDispatchDate({ date: new Date(date), requestBody: [par.sku] });

    dispatch(localCartSkusSet([par]));
});

export const validateAddress = createAsyncThunk<
    void,
    void,
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>('/cart/validateAddress', async (_, { getState, dispatch }) => {
    const cartState = getState().cart;
    const validAddress = isValidAddress(cartState.b2bCheckout.delivery);
    dispatch(addressValidated({ valid: validAddress! }));
});

export const toastAdding = createAsyncThunk<void, CartToastData, ThunkConf>('/cart/toastAdding', async (toastData) => {
    // Cart toast is dismissed, break.
    if (localStorage.getItem('toast_cart_dismissed')) return;

    toast(() => <CartToast data={{ ...toastData, adding: true }} />, {
        autoClose: 5000,
        toastId: 'cartAdd_' + toastData.id,
    });
});

export const toastAdded = createAsyncThunk<void, CartToastData, ThunkConf>('/cart/toastAdded', async (updateData) => {
    toast.update('cartAdd_' + updateData.id, {
        render: () => <CartToast data={updateData} />,
        autoClose: 2500,
    });
});

export const toastDismissed = createAsyncThunk<void, { text: string }, ThunkConf>(
    '/cart/toastDismissed',
    async (toastData) => {
        toast(() => <DismissToast type="cart" text={toastData.text} />, {
            autoClose: 5000,
            toastId: 'cartDismissed',
        });
    },
);

export type CartDelivery = Delivery & {
    agreement?: boolean;
};

type CartState = {
    loading: boolean;
    error: string | null;
    localCart: LocalCart;
    confirm: CheckoutConfirm | null;
    cart: CartSummary;
    standardCheckout: {
        address: StandardCheckout & {
            emailMarketing: boolean;
            marketing: boolean;
            agreement: boolean;
            serverLoaded: boolean;
        };
        loadingAddress: boolean;
    };
    b2bCheckout: {
        delivery?: CartDelivery;
        valid: {
            address: Valid;
            info: Valid;
            all: Valid;
        };
    };
    loadingOrder: boolean;
    minDate?: Date;
    canSetDates?: boolean;
};

const initialState: CartState = {
    loading: false,
    error: null,
    localCart: [],
    cart: {},
    confirm: null,
    standardCheckout: {
        address: {
            emailMarketing: false,
            marketing: false,
            firstName: '',
            lastName: '',
            address1: '',
            address2: '',
            postalCode: '',
            city: '',
            country: '',
            email: '',
            phone: '',
            emailLocked: false,
            comment: '',
            agreement: false,
            serverLoaded: false,
        },
        loadingAddress: false,
    },
    b2bCheckout: {
        delivery: undefined,
        valid: {
            address: Valid.Unknown,
            info: Valid.Unknown,
            all: Valid.Unknown,
        },
    },

    loadingOrder: false,
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        setCart(state, action: PayloadAction<CartSummary>) {
            state.cart = action.payload;
        },
        setError(state, action: PayloadAction<string>) {
            state.error = action.payload;
        },
        setStandardAddressProperty(state, action: PayloadAction<{ key: string; value: string | boolean }>) {
            const { key, value } = action.payload;
            state.standardCheckout.address = {
                ...state.standardCheckout.address,
                [key]: value,
            };
        },
        deliverySet(state, action: PayloadAction<{ name: keyof CartDelivery; value: string | number }>) {
            if (state.b2bCheckout.delivery === undefined) state.b2bCheckout.delivery = { companyId: '' };
            state.b2bCheckout.delivery = {
                ...state.b2bCheckout.delivery,
                [action.payload.name]: action.payload.value ?? '',
            };
            state.b2bCheckout.valid.all = Valid.Unknown;
        },
        addressEdited(state) {
            state.b2bCheckout.valid.address = Valid.Unknown;
            state.b2bCheckout.valid.all = Valid.Unknown;
        },
        addressValidated(state, action: PayloadAction<{ valid: Valid }>) {
            state.b2bCheckout.valid.address = action.payload.valid ?? Valid.Unknown;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadCart.fulfilled, (state, action: PayloadAction<CartSummary | undefined>) => {
            state.cart = { ...action.payload };
            state.loading = false;
        });
        builder.addCase(loadCart.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(loadCart.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(trySetInitialAddress.pending, (state) => {
            state.standardCheckout.loadingAddress = true;
        });
        builder.addCase(trySetInitialAddress.fulfilled, (state, action: PayloadAction<Checkout | undefined>) => {
            state.standardCheckout.address = {
                ...state.standardCheckout.address,
                ...action.payload?.standardCheckout,
                serverLoaded: true,
            };
            state.standardCheckout.loadingAddress = false;
        });
        builder.addCase(trySetInitialAddress.rejected, (state) => {
            state.standardCheckout.loadingAddress = false;
        });
        builder.addCase(confirmCheckout.pending, (state) => {
            state.confirm = null;
            state.loading = true;
        });
        builder.addCase(confirmCheckout.fulfilled, (state, action: PayloadAction<CheckoutConfirm | undefined>) => {
            const { success, orderNumber, errors, cart } = action.payload!;

            state.confirm = { ...state.confirm, success, orderNumber, errors };
            state.cart = { ...cart };
            state.loading = false;
        });
        builder.addCase(addChangeSku.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addChangeSku.fulfilled, (state, action: PayloadAction<CartSummary>) => {
            state.cart = { ...state.cart, ...action.payload };
            state.loading = false;
        });
        builder.addCase(removeFromCart.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(removeFromCart.fulfilled, (state, action: PayloadAction<CartSummary>) => {
            state.cart = { ...state.cart, ...action.payload };
            state.loading = false;
        });
        builder.addCase(setVariationQuantity.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(setVariationQuantity.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(setVariationQuantity.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(localCartSkusSet, (state, action) => {
            action.payload.forEach((input) => {
                const {
                    variationNumber,
                    min,
                    sku,
                    qty,
                    comment,
                    date,
                    inboxItems,
                    additive,
                    addOns,
                    split,
                    collectionId,
                } = input;

                const current: LocalCartVariation = state.localCart.find((cv) =>
                    isSameCollectionVariation(cv, input),
                ) ?? {
                    variationNumber: variationNumber,
                    variationQty: 0,
                    split,
                    collectionId,
                    skus: {},
                };

                const existingAddonIds = (current.skus[sku]?.addOns ?? []).map((existingAddon) => existingAddon.id);
                const newAddons = (addOns ?? []).filter((inputAddon) => !existingAddonIds.includes(inputAddon.id));

                const currentQty = current.skus[sku]?.qty;
                const quantity = typeof qty === 'number' ? qty + (additive && currentQty ? currentQty : 0) : currentQty;

                const totalAddons = (current.skus[sku]?.addOns ?? []).map((addOn) => {
                    const addonToAdd = addOns?.find((a) => a.id === addOn.id);
                    const addOnQuantity = addonToAdd?.quantity ?? 0;

                    return {
                        id: addOn.id,
                        inputs: [...(addOn?.inputs ?? []), ...(addonToAdd?.inputs ?? [])],
                        quantity: (addOn?.quantity ?? 0) + addOnQuantity,
                    } as LocalCartInputAddon;
                });

                const modifiedSkus = {
                    ...current.skus,
                    [sku]: {
                        qty: quantity,
                        comment: typeof comment === 'string' ? comment : current.skus[sku]?.comment,
                        date: date !== undefined ? prepareDate(date, true) : current.skus[sku]?.date,
                        inboxItems: typeof inboxItems === 'number' ? inboxItems : current.skus[sku]?.inboxItems,
                        addOns: totalAddons.concat(newAddons),
                    },
                };

                const variationQty = Object.keys(modifiedSkus).reduce((acc, key) => {
                    return acc + modifiedSkus[key].qty;
                }, 0);

                const newVariation: LocalCartVariation = {
                    ...current,
                    minQty: current.minQty ?? min ?? 0,
                    variationQty,
                    skus: modifiedSkus,
                };

                state.localCart = [
                    ...state.localCart.filter((cv) => !isSameCollectionVariation(cv, newVariation)),
                    newVariation,
                ];
            });
        });
        builder.addMatcher(
            (action) => action.type.startsWith('cart/') && action.type.endsWith('fulfilled'),
            (state, action: PayloadAction<Cart>) => {
                state.cart = { ...action.payload, quantity: action.payload.quantity ?? 0 };
                state.loading = false;

                let localCart: LocalCart = [];
                let minDate: Date | undefined = undefined;
                let canSetDates = false;

                action.payload.items?.forEach((p) =>
                    p.variations?.forEach((v) => {
                        if (!v.number) return;

                        const variationNumber = v.number;
                        const collectionId = v.collectionId ?? undefined;
                        const split = v.split;

                        const cartVariation = {
                            variationNumber,
                            skus: {},
                            variationQty: 0,
                            collectionId,
                            split,
                        };
                        const localCartVariation: LocalCartVariation =
                            localCart.find((cv) => {
                                return isSameCollectionVariation(cv, cartVariation);
                            }) ?? cartVariation;

                        v.skus?.forEach((s) => {
                            if (!s.sku) return;
                            const dDate = s.dispatchDate && new Date(s.dispatchDate);
                            const rdDate = s.requestedDispatchDate && new Date(s.requestedDispatchDate);

                            const date = rdDate
                                ? prepareDate(rdDate, true)
                                : dDate
                                ? prepareDate(dDate, false)
                                : undefined;

                            localCartVariation.skus[s.sku] = {
                                qty: s.quantity || 0,
                                comment: s.comment || undefined,
                                date,
                                inboxItems: 0, //s.price?.inboxItems,
                                addOns: s.addOns ?? undefined,
                            };

                            if (date && !date.unknown) canSetDates = true;
                            if (!date?.unknown && dDate && (!minDate || minDate < dDate)) minDate = dDate;
                        });

                        const variationQty = Object.keys(localCartVariation.skus).reduce((acc, key) => {
                            return acc + localCartVariation.skus[key].qty;
                        }, 0);

                        localCartVariation.variationQty = variationQty;

                        localCart = [
                            ...localCart.filter((cv) => {
                                return !isSameCollectionVariation(cv, localCartVariation);
                            }),
                            localCartVariation,
                        ];
                    }),
                );

                state.minDate = minDate;
                state.localCart = localCart;
                state.canSetDates = canSetDates;
            },
        );
    },
});

export const loadCart = createAsyncThunk<
    CartSummary | undefined,
    void,
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>('cart/load', async (_, { dispatch }) => {
    try {
        const response = await services.cart.fetch();

        return response;
    } catch (e) {
        dispatch(setError('An exception occured when loading the cart'));
    }
});

export const trySetInitialAddress = createAsyncThunk<
    Checkout | undefined,
    void,
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>('cart_checkout/try_set_initial_address', async (_, { getState, rejectWithValue }) => {
    const state = getState();
    const address = state.cart.standardCheckout.address;
    if (!address.serverLoaded) {
        try {
            const response = await services.cart.checkout();
            return response;
        } catch (e) {
            rejectWithValue('');
        }
    }
});

export const addChangeSku = createAsyncThunk<
    CartSummary,
    {
        sku: string;
        quantity: number;
        collectionId?: number | null;
        split?: string | null;
        splitId?: number | undefined;
        addOns?: CartItemSkuAddOn[];
        inputs?: CartInput[];
    },
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>(
    'cart/add_change_sku',
    async ({ sku, quantity, addOns, collectionId, split, splitId, inputs }, { dispatch, getState }) => {
        return await services.cart.changeSkuQuantity({
            cartSkuAdd: [
                {
                    sku,
                    quantity: Math.max(quantity, 0),
                    collection: collectionId,
                    split,
                    splitId,
                    addons: addOns,
                    inputs,
                },
            ],
        });
    },
);

export const addChangeComment = createAsyncThunk<
    CartSummary,
    { skuPattern: string; comment: string },
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>('cart/add_change_comment', async ({ skuPattern, comment }, { dispatch, getState }) => {
    return await services.cart.changeSkuComment({
        cartSkuComment: { value: comment, skuPattern },
    });
});

export const removeFromCart = createAsyncThunk<
    CartSummary,
    { sku: string },
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>('cart/remove_from_cart', async ({ sku }, { dispatch, getState }) => {
    return await services.cart.removeSku({
        body: sku,
    });
});

export const confirmB2BCheckout = createAsyncThunk<
    CheckoutConfirm | undefined,
    { b2bCheckout: B2BCheckout },
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>('cart/confirm_checkout', async (data, { dispatch, getState, rejectWithValue }) => {
    try {
        const response = await services.cart.checkoutConfirm({
            checkout: {
                b2BCheckout: data.b2bCheckout,
            },
        });
        return response;
    } catch (e) {
        rejectWithValue('');
    }
});

export const confirmCheckout = createAsyncThunk<
    CheckoutConfirm | undefined,
    { standardCheckout: StandardCheckout },
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>('cart/confirm_checkout', async (data, { dispatch, getState, rejectWithValue }) => {
    try {
        const response = await services.cart.checkoutConfirm({
            checkout: {
                standardCheckout: data.standardCheckout,
            },
        });
        return response;
    } catch (e) {
        rejectWithValue('');
    }
});

function unknown(date: Date, unknownDateIfDays?: number) {
    if (unknownDateIfDays) {
        const unknownDaysDate = Date.now() + 1000 * 60 * 60 * 24 * unknownDateIfDays;
        const compareDateUnknown = new Date(unknownDaysDate);

        return date >= compareDateUnknown;
    }
    return false;
}

function prepareDate(date: Date, requested: boolean, unknownDateIfDays?: number): LocalDate {
    const reqNote = requested ? i18next.t('shipment.requestedDate', 'Requested date') : undefined;

    const compareDate = new Date(Date.now() + 1000 * 60 * 60 * 24); // If deliv.date is not today it means the article is not in stock and we add a warning.

    const warnDate = date > compareDate ? i18next.t('shipment.warningLongDelivery', 'Note del. date') : undefined;

    return {
        date,
        requested,
        unknown: unknown(date, unknownDateIfDays),
        note: reqNote || warnDate,
    };
}

export type SetVariationQuantityData = {
    name?: string;
    variations: Array<{
        variationNumber: string;
        split?: string | null;
        collectionId?: number;
        color?: string;
        img?: string;
        price?: number;
        currency?: string;
    }>;
    toast?: boolean;
    size?: string;
    productNumber: string;
    brand?: string;
    category?: string;
    append?: boolean;
};

type AddSku = {
    sku: string;
    split?: string | null;
    collection?: number;
    quantity: number;
    boxItems?: number;
};

export const setVariationQuantity = createAsyncThunk<
    Cart | null,
    Partial<SetVariationQuantityData>,
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>('cart/setVariationQuantity', async (par, { dispatch, getState, rejectWithValue }) => {
    const cart = getState().cart;
    const productInputs = getState().product.product.inputs;
    let allSkus: AddSku[] = [];
    const updateToasts: CartToastData[] = [];

    const uniqueVariations = [
        ...new Map(
            par.variations
                ?.filter(Boolean)
                .map((item) => [`${item.variationNumber}${item.collectionId ?? ''}${item.split ?? ''}`, item]),
        ).values(),
    ];

    uniqueVariations?.forEach((vn) => {
        const localCartVariation = cart.localCart.find((cv) => isSameCollectionVariation(cv, vn));
        if (!localCartVariation) return rejectWithValue('No local variation, should not happen');
        const skus = Object.keys(localCartVariation.skus).map<AddSku>((key) => {
            const sku = localCartVariation.skus[key];
            return {
                sku: key,
                quantity: par.append ? sku.qty : 1,
                boxItems: sku.inboxItems,
                addons: sku.addOns,
                split: localCartVariation.split,
                collection: localCartVariation.collectionId,
                inputs: productInputs,
            };
        });

        const cartProduct = cart.cart?.items?.find((p) => p.number === par.productNumber);
        const cartVariationSkus = cartProduct?.variations?.find((v) => v.number === vn.variationNumber)?.skus || [];

        let filteredSkus = skus.filter((sku) => {
            const cartSku = cartVariationSkus.find((s) => s.sku === sku.sku);
            if (!cartSku) {
                return sku.quantity > 0;
            }

            return sku.quantity === 0 || cartSku.quantity !== sku.quantity;
        });

        // Return only last if not append.
        if (!par.append) {
            filteredSkus = filteredSkus.slice(-1);
        }

        if (!filteredSkus.length) return;

        const isUnderMin = localCartVariation.minQty && localCartVariation.variationQty < localCartVariation.minQty;

        if (isUnderMin) {
            filteredSkus = cartVariationSkus.map((s) => ({
                sku: s.sku!,
                quantity: 0,
                boxItems: localCartVariation.skus[s.sku!].inboxItems,
            }));
        }

        allSkus = allSkus.concat(filteredSkus);

        if (par.toast) {
            const toastData: CartToastData = {
                name: par.name,
                quantity: isUnderMin ? 0 : localCartVariation.variationQty || 0,
                color: vn.color,
                img: vn.img,
                id: vn.variationNumber,
            };
            dispatch(toastAdding(toastData));
            updateToasts.push(toastData);
        }
    });

    if (!allSkus.length) return rejectWithValue('no change');

    const response = await services.cart.changeSkuQuantity({ cartSkuAdd: allSkus });
    updateToasts.forEach((toast) => {
        dispatch(toastAdded(toast));
    });
    return response;
});

export function getProductAddData(
    product: ShopCompleteProduct,
    variations: CollectionVariation[],
    category?: string,
    toast?: boolean,
): SetVariationQuantityData {
    return {
        name: product?.productName || undefined,
        variations: variations.map((v) => ({
            variationNumber: v?.variationNumber!,
            split: v.split,
            collectionId: v.collectionId,
            color: v?.color || undefined,
            img: getImage(v?.image, ImageType.ThumbNail, ImageExtension.Jpg) || undefined,
            price: v.price?.customer?.num,
            currency: v.price?.currency || undefined,
        })),
        toast: toast || false,
        productNumber: product?.productNumber!,
        brand: product?.productBrandName || undefined,
        category,
    };
}

function isValidAddress(delivery?: CartDelivery) {
    if (!delivery) return Valid.Invalid;

    if (delivery.company && delivery.company?.length > 30) return Valid.Invalid;
    if (delivery.company && delivery.company?.length < 2) return Valid.Invalid;
    if (delivery.address1 && delivery.address1?.length > 35) return Valid.Invalid;
    if (delivery.postalCode && delivery.postalCode?.length > 16) return Valid.Invalid;
    if (delivery.city && delivery.city?.length > 35) return Valid.Invalid;
    if (delivery.address2 && delivery.address2?.length > 35) return Valid.Invalid;

    if (delivery.email && delivery.email?.length > 50) return Valid.Invalid;
    if (delivery.email && delivery.email?.indexOf('@') === -1) return Valid.Invalid;
    if (delivery.companyId && delivery.companyId?.length > 50) return Valid.Invalid;

    if (delivery.addressId) {
        if (delivery.addressId !== 999) return Valid.Valid;

        if (delivery.company && delivery.address1 && delivery.postalCode && delivery.city) {
            return Valid.Valid;
        } else {
            return Valid.Invalid;
        }
    }
}

export type CartCollectionVariation = {
    variationNumber?: string | null;
    collectionId?: number | null;
    split?: string | null;
};

export function isSameCollectionVariation(
    cartVariationOne?: CartCollectionVariation | null,
    cartVariationTwo?: CartCollectionVariation | null,
) {
    if (!cartVariationOne || !cartVariationTwo) return false;
    return (
        cartVariationOne.variationNumber === cartVariationTwo.variationNumber &&
        cartVariationOne.split === cartVariationTwo.split &&
        cartVariationOne.collectionId === cartVariationTwo.collectionId
    );
}

const selectB2BDeliveryOptions = (state: RootState) => state.user.userIdentity?.orderOptions?.deliveryAddresses;
const selectB2BDelivery = (state: RootState) => state.cart?.b2bCheckout?.delivery?.addressId;

export const selectedB2BDeliveryAddress = createSelector(
    selectB2BDeliveryOptions,
    selectB2BDelivery,
    (options, selectedId) => {
        const selected = options?.find((option) => option?.id === `${selectedId}`);
        return {
            ...selected,
            address1: selected?.street ?? '',
            address2: `${selected?.street2 ?? ''} ${selected?.street3 ?? ''}`,
            country: selected?.country ?? '',
            postalCode: selected?.zipCode ?? '',
            city: selected?.city ?? '',
            email: selected?.email ?? '',
            company: selected?.name ?? '',
        };
    },
);

export const {
    setLoading,
    setCart,
    setError,
    setStandardAddressProperty,
    deliverySet,
    addressEdited,
    addressValidated,
} = cartSlice.actions;
export default cartSlice.reducer;
