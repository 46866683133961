import ProductBuy from 'components/Product/ProductBuy';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
// import useBreakPoints from 'hooks/useBreakPoints';
import { Col, Row } from 'reactstrap';
import {
    inputsSelected,
    selectProductDetails,
    selectProductInputs,
    variationSelected,
} from 'store/reducers/productSlice';
import styled from 'styled-components/macro';
import ProductDetailPageImage from './ProductDetailPageImage';
import ProductDetailPageInfo from './ProductDetailPageInfo';
import ProductUsp from './ProductUsp';
import ProductDetailPageAccordion from './ProductDetailPageAccordion';

const ProductDetailPage = () => {
    const dispatch = useAppDispatch();
    // const bp = useBreakPoints();
    const { product, variation, variations, loading } = useAppSelector(selectProductDetails);
    const productInputs = useAppSelector(selectProductInputs);

    return (
        <FullScreenWrapper>
            {/* <Container className="p-0"> */}
            <Row itemScope itemType="https://schema.org/Product" className="m-0">
                <ProductDetailPageImage loading={loading} />

                <Col xs="12" md="7" xl="6" className="p-0">
                    <ProductDetailPageInfo />
                    <ProductBuy
                        product={product}
                        variation={variation}
                        variations={variations}
                        pending={loading}
                        inputs={productInputs}
                        onVariationSelected={(v) => dispatch(variationSelected(v))}
                        onCollectionInputChange={(v) => dispatch(inputsSelected(v))}
                    />
                    {product?.attributes?.usp && <ProductUsp className="mt-3" usps={product.attributes.usp} />}
                    <ProductDetailPageAccordion />
                </Col>
            </Row>
            {/* {bp.xlBelow && <ProductDetailPageTabs wrapped />} */}
            {/* </Container> */}
        </FullScreenWrapper>
    );
};

const FullScreenWrapper = styled.div`
    overflow: hidden;
`;

export default ProductDetailPage;
