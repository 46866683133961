"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CartSkuAddToJSON = exports.CartSkuAddFromJSONTyped = exports.CartSkuAddFromJSON = exports.instanceOfCartSkuAdd = void 0;
var runtime_1 = require("../runtime");
var CartCustomProduct_1 = require("./CartCustomProduct");
var CartInput_1 = require("./CartInput");
/**
 * Check if a given object implements the CartSkuAdd interface.
 */
function instanceOfCartSkuAdd(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfCartSkuAdd = instanceOfCartSkuAdd;
function CartSkuAddFromJSON(json) {
    return CartSkuAddFromJSONTyped(json, false);
}
exports.CartSkuAddFromJSON = CartSkuAddFromJSON;
function CartSkuAddFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'sku': !(0, runtime_1.exists)(json, 'sku') ? undefined : json['sku'],
        'quantity': !(0, runtime_1.exists)(json, 'quantity') ? undefined : json['quantity'],
        'addons': !(0, runtime_1.exists)(json, 'addons') ? undefined : (json['addons'] === null ? null : json['addons'].map(CartCustomProduct_1.CartCustomProductFromJSON)),
        'inputs': !(0, runtime_1.exists)(json, 'inputs') ? undefined : (json['inputs'] === null ? null : json['inputs'].map(CartInput_1.CartInputFromJSON)),
        'collection': !(0, runtime_1.exists)(json, 'collection') ? undefined : json['collection'],
        'splitId': !(0, runtime_1.exists)(json, 'splitId') ? undefined : json['splitId'],
        'split': !(0, runtime_1.exists)(json, 'split') ? undefined : json['split'],
    };
}
exports.CartSkuAddFromJSONTyped = CartSkuAddFromJSONTyped;
function CartSkuAddToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'sku': value.sku,
        'quantity': value.quantity,
        'addons': value.addons === undefined ? undefined : (value.addons === null ? null : value.addons.map(CartCustomProduct_1.CartCustomProductToJSON)),
        'inputs': value.inputs === undefined ? undefined : (value.inputs === null ? null : value.inputs.map(CartInput_1.CartInputToJSON)),
        'collection': value.collection,
        'splitId': value.splitId,
        'split': value.split,
    };
}
exports.CartSkuAddToJSON = CartSkuAddToJSON;
