"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CartInputToJSON = exports.CartInputFromJSONTyped = exports.CartInputFromJSON = exports.instanceOfCartInput = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the CartInput interface.
 */
function instanceOfCartInput(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfCartInput = instanceOfCartInput;
function CartInputFromJSON(json) {
    return CartInputFromJSONTyped(json, false);
}
exports.CartInputFromJSON = CartInputFromJSON;
function CartInputFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'key': !(0, runtime_1.exists)(json, 'key') ? undefined : json['key'],
        'input': !(0, runtime_1.exists)(json, 'input') ? undefined : json['input'],
    };
}
exports.CartInputFromJSONTyped = CartInputFromJSONTyped;
function CartInputToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'key': value.key,
        'input': value.input,
    };
}
exports.CartInputToJSON = CartInputToJSON;
