import Flex from 'components/ui/Flex';
import useAppSelector from 'hooks/useAppSelector';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import styled from 'styled-components/macro';
import * as themeHelper from 'theme/functions';
import Text from 'components/ui/Text';

const ShopLoadLayout = ({ children }: { children: React.ReactNode }) => {
    const { info, theme, unit } = useAppSelector(({ shop }) => ({ ...shop, unit: shop?.shop?.selectedUnit }));

    return (
        <StyledBackground
            className="justify-content-center align-items-center"
            style={info?.backgroundImage ? { backgroundImage: 'url("' + info.backgroundImage + '")' } : {}}
        >
            <Container>
                <StyledCard className="mx-auto">
                    <StyledCardHeader $color={theme.main} className={`${themeHelper.lightnessClass(theme)}`}>
                        {!!info?.logoType && (
                            <Flex className="justify-content-center pt-4 mb-4">
                                <StyledImage alt="" src={info?.logoType} />
                            </Flex>
                        )}

                        {!info?.logoType && (
                            <div
                                className={`d-flex mt-2 justify-content-center align-items-center ${themeHelper.textClass(
                                    theme,
                                )}`}
                            >
                                <Text fontSize={32} bold>
                                    {unit?.name ?? info?.name}
                                </Text>
                            </div>
                        )}
                    </StyledCardHeader>
                    <CardBody className="px-5">{children}</CardBody>
                </StyledCard>
            </Container>
        </StyledBackground>
    );
};
const StyledCard = styled(Card)`
    max-width: 700px;
    border-radius: 0;
    border: none;
`;

const StyledCardHeader = styled(CardHeader)<{ $color: string }>`
    background-color: ${({ theme }) => theme.colors.fillWhite};
    border: none;
`;

const StyledBackground = styled(Flex)`
    flex-grow: 2;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;

const StyledImage = styled.img`
    max-height: 150px;
    max-width: 100%;
`;

export default ShopLoadLayout;
