import {
    AssortmentApi,
    AssortmentGetCategoriesRequest,
    AssortmentGetProductRequest,
    AssortmentGetProductsRequest,
    FilterOutput,
    ListProductPageInfo,
    ShopCompleteProduct,
    ShopFilterInput,
    SkuPriceInput,
} from 'microshop-api';
import { config } from './api';

const assortmentApi = new AssortmentApi(config);

const assortment = {
    getProduct: ({ category, product }: AssortmentGetProductRequest): Promise<ShopCompleteProduct> => {
        return assortmentApi.assortmentGetProduct({ category, product });
    },
    getProducts: (
        {
            selectedFilters,
            orderBy = 'relevance',
            pageSize = 12,
            categories = undefined,
            page,
        }: AssortmentGetProductsRequest,
        cancelRequest?: AbortSignal,
    ): Promise<ListProductPageInfo> =>
        assortmentApi.assortmentGetProducts(
            { selectedFilters, orderBy, pageSize, page, categories },
            { signal: cancelRequest },
        ),

    getFilters: ({ assortmentIds, filters }: ShopFilterInput, cancelRequest?: AbortSignal): Promise<FilterOutput[]> =>
        assortmentApi.assortmentGetFiltersPOST(
            {
                shopFilterInput: {
                    assortmentIds: assortmentIds,
                    filters: filters,
                },
            },
            { signal: cancelRequest },
        ),
    getPrice: (price: [SkuPriceInput]) => assortmentApi.assortmentPricePOST({ skuPriceInput: price }),
    getCategories: ({ assortmentEntryPoint, includeProducts = false }: AssortmentGetCategoriesRequest) =>
        assortmentApi.assortmentGetCategories({ assortmentEntryPoint, includeProducts }),
};

export { assortment };
