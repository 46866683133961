"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollectionTextInputToJSON = exports.CollectionTextInputFromJSONTyped = exports.CollectionTextInputFromJSON = exports.instanceOfCollectionTextInput = void 0;
var runtime_1 = require("../runtime");
var TextValidation_1 = require("./TextValidation");
/**
 * Check if a given object implements the CollectionTextInput interface.
 */
function instanceOfCollectionTextInput(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfCollectionTextInput = instanceOfCollectionTextInput;
function CollectionTextInputFromJSON(json) {
    return CollectionTextInputFromJSONTyped(json, false);
}
exports.CollectionTextInputFromJSON = CollectionTextInputFromJSON;
function CollectionTextInputFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'maxChars': !(0, runtime_1.exists)(json, 'maxChars') ? undefined : json['maxChars'],
        'validation': !(0, runtime_1.exists)(json, 'validation') ? undefined : (0, TextValidation_1.TextValidationFromJSON)(json['validation']),
        'key': !(0, runtime_1.exists)(json, 'key') ? undefined : json['key'],
    };
}
exports.CollectionTextInputFromJSONTyped = CollectionTextInputFromJSONTyped;
function CollectionTextInputToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'validation': (0, TextValidation_1.TextValidationToJSON)(value.validation),
    };
}
exports.CollectionTextInputToJSON = CollectionTextInputToJSON;
