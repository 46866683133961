import { ApplicationPaths, LoginActions, LogoutActions } from 'components/ApiAuthorization/ApiAuthorizationConstants';
import { Login } from 'components/ApiAuthorization/Login';
import { Logout } from 'components/ApiAuthorization/Logout';
import Cart from 'pages/Cart';
import Home from 'pages/Home';
import Product from 'pages/Product';
import Products from 'pages/Products';
import { Route, Routes } from 'react-router-dom';

function ShopRoutes() {
    return (
        <Routes>
            <Route path="/">
                <Route path="/" element={<Home />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/products/:category" element={<Products />} />
                <Route path="/product/:category?/:productNumber" element={<Product />} />
                {/* <Route path={ApplicationPaths.ApiAuthorizationPrefix}>
                    <Route path={ApplicationPaths.Login} element={loginAction(LoginActions.Login)} />
                    <Route path={ApplicationPaths.LoginFailed} element={loginAction(LoginActions.LoginFailed)} />
                    <Route path={ApplicationPaths.LoginCallback} element={loginAction(LoginActions.LoginCallback)} />
                    <Route path={ApplicationPaths.Profile} element={loginAction(LoginActions.Profile)} />
                    <Route path={ApplicationPaths.Register} element={loginAction(LoginActions.Register)} />
                    <Route path={ApplicationPaths.LogOut} element={logoutAction(LogoutActions.Logout)} />
                    <Route
                        path={ApplicationPaths.LogOutCallback}
                        element={logoutAction(LogoutActions.LogoutCallback)}
                    />
                    <Route path={ApplicationPaths.LoggedOut} element={logoutAction(LogoutActions.LoggedOut)} />
                </Route> */}
                <Route path="/404" element={<></>} />
                <Route path="/*" element={<Products />} />
            </Route>
        </Routes>
    );
}

// function loginAction(name: string) {
//     return <Login action={name}></Login>;
// }

// function logoutAction(name: string) {
//     return <Logout action={name}></Logout>;
// }

export default ShopRoutes;
