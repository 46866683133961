import { faShoppingBag } from '@fortawesome/pro-solid-svg-icons';
import Checkbox from 'components/ui/Checkbox';
import Flex from 'components/ui/Flex';
import IconButton from 'components/ui/IconButton';
import Text from 'components/ui/Text';
import Spinner from 'components/ui/Spinner';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import {
    CartInput,
    ShopCompleteProduct,
    ShopProductAddOn,
    ShopProductAddOnInputType,
    ShopProductAddOnType,
} from 'microshop-api';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { Col, Input } from 'reactstrap';
import {
    LocalCartInput,
    LocalCartInputAddon,
    getProductAddData,
    isSameCollectionVariation,
    localCartSkusSet,
    setVariationQuantity,
} from 'store/reducers/cartSlice';
import { CollectionVariation, OrderLayout } from 'store/reducers/productSlice';
import styled from 'styled-components';
import ArticleSelector from './ArticleSelector';
import ProductMatrix from './ProductMatrix';
import VariationSelector from './VariationSelector';

export type QuantityChangedFunction = (
    sku: string,
    qty: number,
    variation: CollectionVariation,
    boxItems?: number,
    singleSelect?: boolean,
) => void;
export type AddToCartFunction = (variation?: string) => void;

enum ResolvedLayout {
    Normal,
    Matrix,
    Refinement,
}

type ProductBuyProps = {
    product?: ShopCompleteProduct | null;
    variations?: CollectionVariation[];
    variation?: CollectionVariation | null;
    inputs?: CartInput[];
    pending: boolean;
    onVariationSelected: (v: CollectionVariation) => void;
    onCollectionInputChange?: (v: CartInput[]) => void;
    categoryName?: string;
};

type ProductAddon = Pick<ShopProductAddOn, 'inputType'> & {
    id: number;
    name: string;
    selected: boolean;
    input: string;
    mandatory: boolean;
    inputRequired: boolean;
    inputValid?: boolean;
    inputInvalid?: boolean;
    price: string;
};

const ProductBuy: React.FC<ProductBuyProps> = ({
    product,
    variations,
    variation,
    pending,
    inputs,
    onVariationSelected,
    onCollectionInputChange,
    categoryName,
}) => {
    const { localCart, loading } = useAppSelector(({ cart }) => cart);
    const orderLayout = useAppSelector(({ product }) => product.orderLayout);
    const [toOrder, setToOrder] = useState<LocalCartInput[]>([]);
    const [selectedCollectionIndex, setSelectedCollectionIndex] = useState<number>(-1);
    const [variationMinQts, setVarationMinQts] = useState<{ [key: string]: number }>({});
    const [productAddons, setProductAddons] = useState<ProductAddon[]>([]);
    const [productAddonsInvalid, setProductAddonsInvalid] = useState<boolean>(false);
    const [cells, setCells] = useState<Map<string, number | undefined>>(new Map());
    const dispatch = useAppDispatch();
    const isCartAvailable = true;
    const { t } = useTranslation();

    useEffect(() => {
        const cellMap = new Map();
        const addons = (product?.variations?.[0]?.addons ?? []).filter((addon) => addon.id !== undefined);

        product?.variations?.forEach((v) => {
            v.skus?.forEach((sku) => {
                sku.sku && cellMap.set(sku.sku, undefined);
            });
        });

        setProductAddons(
            addons
                ?.map((addon) => ({
                    id: addon.id!,
                    name: addon.productName!,
                    input: '',
                    inputRequired: addon.inputType !== ShopProductAddOnInputType.None,
                    selected: ShopProductAddOnType.Mandatory === addon.type,
                    mandatory: ShopProductAddOnType.Mandatory === addon.type,
                    inputType: addon.inputType,
                    price: `${addon.price}`,
                }))
                .sort((value) => {
                    return value.mandatory ? -1 : 1;
                }),
        );

        setCells(cellMap);
        setVarationMinQts({});
    }, [product]);

    useEffect(() => {
        if (productAddons) {
            const selectedAddons = productAddons.filter((addon) => addon.selected && addon.inputRequired);
            const invalid = selectedAddons.some((addon) => addon.inputInvalid || !addon.input);
            setProductAddonsInvalid(invalid);
        }
    }, [productAddons]);

    useEffect(() => {
        const selectedCollectionIndex = variation?.collections?.findIndex(
            (c) => c.collectionId === variation.collectionId && c.split === variation.split,
        );

        setSelectedCollectionIndex(selectedCollectionIndex ?? -1);
    }, [variation]);

    const emptyCells = (skus: LocalCartInput[]) => {
        const cellMap = new Map(cells);

        skus.forEach((sku) => {
            cellMap.set(sku.sku, undefined);
        });

        setCells(cellMap);
    };

    const handleQuantityChange: QuantityChangedFunction = (sku, qty, variation, inboxItems, singleSelect) => {
        const { collectionId, split, variationNumber } = variation;
        if (Number.isNaN(qty) || !sku || !variationNumber) return;

        let newToOrder: LocalCartInput[] = [];

        if (!singleSelect) {
            const cellMap = new Map(cells);
            cellMap.set(sku, qty);
            setCells(cellMap);

            // Make sure there are no duplicates.
            newToOrder = toOrder.filter((to) => to.sku !== sku);
        }

        const cartVariationQty =
            localCart.find((cv) => isSameCollectionVariation(cv, { variationNumber, split, collectionId }))
                ?.variationQty ?? 0;
        const minQty =
            (product?.variations?.find((v) => v.variationNumber === variationNumber)?.minQuantity || 1) -
            cartVariationQty;
        const toOrderVariationQty =
            newToOrder
                .filter(
                    (to) =>
                        to.variationNumber === variationNumber &&
                        to.split === split &&
                        to.collectionId === collectionId,
                )
                .reduce((acc, cur) => acc + (cur.qty || 0), 0) + qty;

        setVarationMinQts({ ...variationMinQts, [variationNumber]: minQty - (toOrderVariationQty ?? minQty) });

        if (qty >= 1) {
            const input: LocalCartInput = {
                variationNumber: variationNumber,
                split,
                collectionId,
                sku,
                qty,
                inboxItems,
                additive: true,
            };
            newToOrder.push(input);
        }

        setToOrder(newToOrder);
    };

    useEffect(() => {
        if (!product?.hasVariations) {
            if (variation && variation?.skus?.[0].sku) {
                handleQuantityChange(
                    variation?.skus?.[0].sku,
                    1,
                    variation,
                    variation?.skus?.[0].price?.inboxItems,
                    true,
                );
            }
        }
    }, [variation]);

    if (!product) return null;

    const layout = getLayout(product, orderLayout);
    const handleAddToCart = () => {
        const collectionHasInputs = !!variation?.collections?.[selectedCollectionIndex]?.inputs?.length;
        const skusWithAddons = toOrder.map((toOrder) => ({
            ...toOrder,
            addOns: productAddons.reduce((acc: LocalCartInputAddon[], selectedAddon) => {
                if (toOrder.qty && selectedAddon.selected) {
                    acc.push({
                        id: selectedAddon.id,
                        quantity: toOrder.qty,
                        inputs: selectedAddon.inputRequired
                            ? Array.from({ length: toOrder.qty }, () => selectedAddon.input)
                            : null,
                    });
                }
                return acc;
            }, []),
        }));
        const variationNumber = layout === ResolvedLayout.Normal ? variation?.variationNumber : undefined;

        // If variation is provided, only add from that row of the matrix. (OrderLayout.Normal)
        const skusToOrder = !variationNumber
            ? skusWithAddons
            : skusWithAddons.filter((to) => isSameCollectionVariation(to, variation));
        const restToOrder =
            layout === ResolvedLayout.Normal
                ? toOrder
                : !variationNumber
                ? []
                : skusWithAddons.filter((to) => !isSameCollectionVariation(to, variation)); // The rest of the rows after this color is added

        dispatch(localCartSkusSet(skusToOrder));
        emptyCells(skusToOrder);

        const collectionVariations = skusToOrder
            .map((s) => variations?.find((v) => isSameCollectionVariation(v, s)))
            .filter((v): v is CollectionVariation => !!v);

        dispatch(
            setVariationQuantity({
                append: !collectionHasInputs,
                ...getProductAddData(product, collectionVariations, categoryName, true),
            }),
        );
        setToOrder(restToOrder);
    };

    if (pending) return <Skeleton height={400} width="94%" className="m-4"></Skeleton>;

    const renderOrderLayout = () => {
        switch (layout) {
            case ResolvedLayout.Matrix:
                return (
                    <ProductMatrix
                        className="mb-4"
                        variations={variations}
                        selectedVariation={variation}
                        onVariationSelect={onVariationSelected}
                        onAddToCart={handleAddToCart}
                        onQuantityChange={handleQuantityChange}
                        cells={cells}
                        addToCartEnabled={true}
                        variationMinQts={variationMinQts}
                        compact={orderLayout === OrderLayout.MatrixCompact}
                    />
                );
            case ResolvedLayout.Normal:
            default:
                return (
                    <>
                        {variations?.length && product.hasVariations && (
                            <VariationSelector
                                variations={variations}
                                selectedCollectionIndex={selectedCollectionIndex}
                                selectedVariation={variation}
                                selectedInputs={inputs}
                                onSelect={onVariationSelected}
                                onCollectionInputChange={onCollectionInputChange}
                            />
                        )}
                        {variation && product.hasVariations && (
                            <ArticleSelector
                                key={`${variation.variationNumber}-${variation.split}-${variation.collectionId}`}
                                variation={variation}
                                onQuantityChange={handleQuantityChange}
                                cells={cells}
                                addToCartEnabled={true}
                            />
                        )}
                    </>
                );
        }
    };

    const selectedAddonChange = (id: number, currentTarget: HTMLInputElement) => {
        setProductAddons((prev) => {
            const currentPrev = [...prev];
            const currentAddonIndex = currentPrev.findIndex((selectedAddons) => selectedAddons.id === id);
            const currentAddon = currentPrev[currentAddonIndex];
            const inputValid = !!currentTarget.value!;
            const newValue = {
                ...currentAddon,
                input: currentTarget.value!,
                inputValid: inputValid,
                inputInvalid: !inputValid,
            };
            currentPrev.splice(currentAddonIndex, 1, newValue);
            return currentPrev;
        });
    };

    const renderAddons = () => {
        return (
            productAddons.length > 0 && (
                <Flex justify="between" column className="mb-4">
                    <Text className="mb-2 f3-700" light>
                        {t('product_addOn', 'Addons')}
                    </Text>
                    <Flex column>
                        {productAddons.map((addon, index) => {
                            return (
                                <div className="row" key={'addon' + addon.id + index}>
                                    <Col sm="1">
                                        <Checkbox
                                            className="mb-2"
                                            name={addon.name}
                                            checked={addon.selected}
                                            disabled={addon?.mandatory}
                                            onChange={() => {
                                                if (addon?.mandatory) return;
                                                setProductAddons((prev) => {
                                                    const index = prev.findIndex((a) => a.id === addon.id);
                                                    const currentList = [...prev];
                                                    const currentAddon = { ...currentList[index] };
                                                    const resetValidation = {
                                                        inputValid: undefined,
                                                        inputInvalid: undefined,
                                                    };

                                                    currentList[index] = {
                                                        ...currentAddon,
                                                        selected: !currentAddon.selected,
                                                        input: '',
                                                        ...(currentAddon.selected ? resetValidation : undefined),
                                                    };

                                                    return currentList;
                                                });
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        {addon.inputRequired && (
                                            <Input
                                                required
                                                key={`${addon.selected}`}
                                                disabled={!addon.selected}
                                                className="mb-2"
                                                placeholder={addon.inputRequired && `${addon.name} (${addon.price})`}
                                                maxLength={100}
                                                value={addon.input ?? ''}
                                                valid={addon.inputValid}
                                                invalid={addon.inputInvalid}
                                                type={
                                                    addon.inputType === ShopProductAddOnInputType.Text
                                                        ? 'text'
                                                        : 'number'
                                                }
                                                addon={true}
                                                onChange={(e) => selectedAddonChange(addon.id!, e.currentTarget)}
                                                onBlur={(e) => selectedAddonChange(addon.id!, e.currentTarget)}
                                            />
                                        )}
                                        {!addon.inputRequired && `${addon.name} (${addon.price})`}
                                    </Col>
                                </div>
                            );
                        })}
                    </Flex>
                    {/* <pre>{JSON.stringify(productAddons, undefined, 4)}</pre> */}
                </Flex>
            )
        );
    };
    const addToCartButtonAutoWidth =
        layout === ResolvedLayout.Normal && !!variation?.skus && variation?.skus.length <= 1;

    const addToCartActive =
        (layout === ResolvedLayout.Matrix
            ? !!toOrder.length
            : !!toOrder.find(
                  (to) =>
                      to.variationNumber === variation?.variationNumber && to.collectionId === variation.collectionId,
              )) &&
        (!Object.keys(variationMinQts).find((k) => variationMinQts[k] > 0) || productHasQuantity(cells));

    const collectionInputsInvalid =
        variation?.collections?.[selectedCollectionIndex]?.inputs?.some(
            (input) => !inputs?.find((vInput) => vInput.key === input.key)?.input,
        ) ?? false;

    const oneSize = !product?.variations?.some((variation) => variation?.skus?.length! > 1);

    return (
        <div className="px-2 px-md-4 pt-4 mt-1 pb-4 f3-400">
            {renderOrderLayout()}
            {renderAddons()}
            {
                <AddToCart align="center" justify={oneSize ? 'start' : 'center'} className="mb-3">
                    {isCartAvailable && (
                        <AddToCartButton
                            large
                            color="accentText"
                            icon={faShoppingBag}
                            bgColor="buy"
                            onClick={handleAddToCart}
                            disabled={!addToCartActive || collectionInputsInvalid || productAddonsInvalid || loading}
                            autoWidth={addToCartButtonAutoWidth}
                        >
                            {!loading ? t('cart_add_one', 'Add to cart') : <Spinner />}
                        </AddToCartButton>
                    )}
                </AddToCart>
            }
        </div>
    );
};

export default ProductBuy;

function getLayout(product: ShopCompleteProduct, orderLayout: OrderLayout): ResolvedLayout {
    if (!product.hasVariations) return ResolvedLayout.Normal;
    return orderLayout === OrderLayout.Normal ? ResolvedLayout.Normal : ResolvedLayout.Matrix;
}

function productHasQuantity(cells: Map<string, number | undefined>): boolean {
    for (let cell of cells.entries()) {
        if (cell[1] != null && cell[1] > 0) return true;
    }

    return false;
}

const AddToCart = styled(Flex)`
    & > button {
        flex-grow: 1;
    }
`;

const AddToCartButton = styled(IconButton)<{ autoWidth: boolean }>`
    max-width: ${({ autoWidth }) => (autoWidth ? '245px' : 'none')};
`;
