"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopAssortmentTypeToJSON = exports.ShopAssortmentTypeFromJSONTyped = exports.ShopAssortmentTypeFromJSON = exports.ShopAssortmentType = void 0;
/**
 *
 * @export
 */
exports.ShopAssortmentType = {
    Custom: 0,
    Full: 1
};
function ShopAssortmentTypeFromJSON(json) {
    return ShopAssortmentTypeFromJSONTyped(json, false);
}
exports.ShopAssortmentTypeFromJSON = ShopAssortmentTypeFromJSON;
function ShopAssortmentTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.ShopAssortmentTypeFromJSONTyped = ShopAssortmentTypeFromJSONTyped;
function ShopAssortmentTypeToJSON(value) {
    return value;
}
exports.ShopAssortmentTypeToJSON = ShopAssortmentTypeToJSON;
