import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import Flex from 'components/ui/Flex';
import IconButton from 'components/ui/IconButton';
import Text from 'components/ui/Text';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import VariationInput from './VariationInput';
import { CollectionVariation, ProductCollectionVariation } from 'store/reducers/productSlice';
import { TextValidationInput } from './TextValidationInput';
import { CartInput } from 'microshop-api';

export type VariationOfferSelectType = {
    variations: Array<{ colorCode: string; variationNumber: string }>;
    productNumber: string;
};

type VariationSelectorProps = {
    className?: string;
    variations: CollectionVariation[];
    selectedCollectionIndex?: number;
    selectedVariation?: CollectionVariation | null;
    selectedInputs?: CartInput[];
    onSelect: (v: CollectionVariation) => void;
    onCollectionInputChange?: (v: CartInput[]) => void;
};
const VariationSelector: React.FC<VariationSelectorProps> = ({
    className,
    variations,
    selectedCollectionIndex = -1,
    selectedVariation,
    selectedInputs,
    onSelect,
    onCollectionInputChange,
}) => {
    const [showAllColors, setShowAllColors] = useState(false);
    const { t } = useTranslation();

    const handleCollectionInputChange = (key: string, value: string) => {
        const updatedInputs =
            selectedInputs?.map((inputObj) => (inputObj.key === key ? { ...inputObj, input: value } : inputObj)) ?? [];
        onCollectionInputChange?.(updatedInputs);
    };

    return (
        <>
            <Flex justify="between" className={className}>
                <Text className="mb-2 f3-700" light>
                    {t('article_one')}
                </Text>
            </Flex>
            <ColorsContainer align="start" className={`${className ? className : ''}`}>
                {variations?.map((v, i) => {
                    return (
                        (showAllColors || i < 6) && (
                            <StyledVariationInput
                                key={`${v.colorCode}${i}`}
                                variation={v}
                                selected={isSelected(v, selectedVariation)}
                                onSelect={onSelect}
                                className="me-1"
                                image
                            />
                        )
                    );
                })}
                {variations.length > 6 && (
                    <IconButton
                        className="w-100 mb-3"
                        bgColor="fillMedium"
                        icon={faPlus}
                        onClick={() => setShowAllColors(!showAllColors)}
                    >
                        {showAllColors
                            ? t('product.showLessColors', 'Show less colors')
                            : variations.length - 6 + ' ' + t('colors', 'colors')}
                    </IconButton>
                )}
            </ColorsContainer>
            {selectedCollectionIndex >= 0 && selectedVariation?.collections?.[selectedCollectionIndex]?.inputs && (
                <InputsContainer>
                    {selectedVariation?.collections?.[selectedCollectionIndex]?.inputs?.map((input, i) => (
                        <InputWrapper key={`text_validation_input_${i}}`}>
                            <Text className="f3-700" light>
                                {input.name}
                            </Text>
                            <StyledTextValidationInput
                                required
                                value={selectedInputs?.find((i) => i.key === input.key)?.input ?? ''}
                                maxChars={input.maxChars}
                                validation={input.validation}
                                onChange={(value) => handleCollectionInputChange(input.key!, value)}
                            />
                        </InputWrapper>
                    ))}
                </InputsContainer>
            )}
        </>
    );
};

const ColorsContainer = styled(Flex)`
    flex-wrap: wrap;
`;

const InputsContainer = styled(Flex)`
    flex-direction: column;
    margin-top: 20px;
    gap: 15px;
`;

const InputWrapper = styled(Flex)`
    flex-direction: column;
`;

const StyledTextValidationInput = styled(TextValidationInput)`
    max-width: 200px;
`;

const StyledVariationInput = styled(VariationInput)``;

export default VariationSelector;

function isSelected(variation: CollectionVariation, selected?: ProductCollectionVariation | null) {
    return (
        selected?.variationNumber === variation.variationNumber &&
        selected?.collectionId === variation.collectionId &&
        selected?.split === variation.split
    );
}
